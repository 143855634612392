let cookieName = "_SAFFRON_ARTISTS_dispense-phone-number-data";
export default (store) => {
  let cookieValue = store.cookie.get(cookieName);
  return {
    // to be used with sg/ss
    state: () => ({
      fullName: cookieValue?.fullName ? cookieValue?.fullName : null,
      phoneNumber: cookieValue?.phoneNumber ? cookieValue?.phoneNumber : null,
    }),
    mutations: {
      setFullName(state, payload) {
        state.fullName = payload;
        store.cookie.set(cookieName, state);
      },
      setPhoneNumber(state, payload) {
        state.phoneNumber = payload;
        store.cookie.set(cookieName, state);
      },
    },
    actions: {},
    getters: {},
  };
};
